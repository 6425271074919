import {qs} from "imagelogic-tools/src/dom/qs";

export class Map {
  constructor() {
    this.initMap();
  }
  initMap() {
    const mapOuter: HTMLElement = qs("#mapOuter");
    const centerPosition: google.maps.LatLngLiteral = {lat: 35.669410, lng: 139.681528};
    const markerPosition: google.maps.LatLngLiteral = {lat: 35.667874, lng: 139.683523};
    const mapOptions: {} = {
      center: centerPosition,
      zoom: 15,
      streetViewControl: true,
    };
    const map: any = new google.maps.Map(mapOuter, mapOptions);
    const lgmarker: any = new google.maps.Marker({
        position: markerPosition,
        map: map,
      }
    );
  }
}
