import {qsa} from "imagelogic-tools/src/dom/qsa";

export class CurrentNav {
  private readonly scrollVal: number;
  private readonly targetSections: HTMLElement[];
  private readonly windowHalfHeight: number;
  constructor() {
    this.targetSections = qsa(".target-section");
    this.scrollVal = window.pageYOffset;
    this.windowHalfHeight = window.outerHeight / 2;
    const targetSectionTops: number[] = [];
    const lgList: HTMLElement[] = qsa("#mainNav ul li");

    for (let  i = 0; i < this.targetSections.length; i++) {
      const targetSectionTop: number = this.targetSections[i].getBoundingClientRect().top + this.scrollVal;
      targetSectionTops.push(targetSectionTop);
      if ( this.scrollVal + this.windowHalfHeight < targetSectionTops[0]) {
        lgList[0].classList.remove("active");
      } else if ( this.scrollVal + this.windowHalfHeight >= targetSectionTops[i]) {
        lgList.forEach((elm) => {
          elm.classList.remove("active");
        });
        lgList[i].classList.add("active");
      }
    }
  }
}
