import {qs} from "imagelogic-tools/src/dom/qs";

export class ShowSPNav {
  constructor() {
    const spHeader: HTMLElement = qs("header.sm-only");
    const scrollVal: number = window.pageYOffset;
    const targetHeight: number = window.innerHeight - 60;
    if (scrollVal >= targetHeight) {
      spHeader.classList.add("show");
    } else {
      spHeader.classList.remove("show");
    }
  }
}
