import {qsa} from "imagelogic-tools/src/dom/qsa";
import {qs} from "imagelogic-tools/src/dom/qs";

export class AccordionMediaInfo {
  private parentList: HTMLElement = qs("#mediaList");
  private infoList: HTMLElement[] = qsa("#mediaList ul");
  private wrapHeights: number[] = [];
  private moreBtns: HTMLElement[] = [];
  private wrapLists: HTMLElement[] = [];
  private listNum: number         = this.infoList.length;
  private showNum: number         = 3;
  constructor() {
    this.hideRestWrap();
    this.clickMoreBtn();
  }
  hideRestWrap() {
    for (let i = 0; i < this.listNum; i++) {
      const items: HTMLElement[] = qsa("li", this.infoList[i]),
            itemNum: number = items.length;
      if (itemNum > this.showNum) {
        const div: HTMLElement = document.createElement("div");
        this.displayMoreBtn(i);
        this.setRestWrapper(div);
        this.storeRestListToArray(div, items, i, itemNum);
        this.storeRestWrapperToArray(div);
        this.storeWrapHeightToArray(div);
        this.resetWrapHeight(div);
      }
    }
  }
  setRestWrapper(div: HTMLElement) {
    div.classList.add("rest-wrapper");
  }
  storeRestWrapperToArray(div: HTMLElement) {
    this.wrapLists.push(div);
  }
  storeRestListToArray(div: HTMLElement, items: HTMLElement[], i: number, itemNum: number) {
    // items[this.showNum].before(div);
    items[this.showNum].parentNode.insertBefore(div, items[this.showNum]);
    div.appendChild(items[this.showNum]);
    for (let j = this.showNum + 1; j < itemNum; j++) {
      const latestElement: HTMLElement = items[j - 1];
      // latestElement.after(items[j]);
      latestElement.parentNode.insertBefore(items[j], latestElement.nextElementSibling);
    }
  }
  displayMoreBtn(i: number) {
    const span: HTMLElement = document.createElement("span");
    span.classList.add("btn-more");
    span.textContent = "(もっと)";
    this.parentList.insertBefore(span, this.infoList[i].nextElementSibling);
    this.moreBtns.push(span);
  }
  storeWrapHeightToArray(div: HTMLElement) {
    this.wrapHeights.push(div.clientHeight);
  }
  resetWrapHeight(div: HTMLElement) {
    div.style.height = "0";
  }
  clickMoreBtn() {
    const wrapNum: number = this.moreBtns.length;
    for (let i = 0; i < wrapNum; i++) {
      this.moreBtns[i].addEventListener("click", () => {
        if (this.wrapLists[i].clientHeight === 0) {
          this.wrapLists[i].style.height = this.wrapHeights[i] + "px";
          this.moreBtns[i].textContent = "(閉じる)";
        } else {
          this.wrapLists[i].style.height = "0";
          this.moreBtns[i].textContent = "(もっと)";
        }
      });
    }
  }
}
