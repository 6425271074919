import {qs} from "imagelogic-tools/src/dom/qs";
import {EVENT_CLICK} from "../Consts";
import {toggleClass} from "imagelogic-tools/src/dom/toggleClass";

export class SPNav {
  private readonly btn: HTMLElement;
  private readonly nav: HTMLElement;
  constructor() {
    this.btn = qs("#smNavBtn");
    this.nav = qs("#smNav");
    this.btn.addEventListener(EVENT_CLICK, () => {
      toggleClass([this.btn, this.nav], "open");
    });
  }
}
