import {qs} from "imagelogic-tools/src/dom/qs";

export class LgNavfix {
  constructor() {
    // const scrollVal: number = window.pageYOffset;
    // const windowHeight = window.innerHeight;
    const lgNav = qs("#nav");
    const topBorder = qs(".top-border");
    const targetTopVal: number = qs("#main").getBoundingClientRect().top;
    if (targetTopVal < 0) {
      lgNav.classList.add("fixed");
      topBorder.classList.add("fixed");
    } else {
      lgNav.classList.remove("fixed");
      topBorder.classList.remove("fixed");
    }
  }
}
