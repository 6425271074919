import {DocBase} from "./DocBase";
import {ScrollManager} from "./main/ScrollManager";
import {CurrentNav} from "./main/CurrentNav";
import {LgNavfix} from "./main/LgNavfix";
import {AccordionMediaInfo} from "./main/AccordionMediaInfo";
import {Map} from "./main/Map";
import {FktyManager} from "./main/FktyManager";
import {SPNav} from "./main/SPNav";
import {ShowSPNav} from "./main/ShowSPNav";
import {MediaQueryHelper} from "imagelogic-tools/src/viewport/MediaQueryHelper";

export class MainDoc extends DocBase {
  private scroll: ScrollManager;
  private currentnav: CurrentNav;
  private lgnavfix: LgNavfix;
  private accordionmediainfo: AccordionMediaInfo;
  private map: Map;
  private flickity: FktyManager;
  private spnav: SPNav;
  private showspnav: ShowSPNav;
  private mqh: MediaQueryHelper;

  constructor() {
    super();
  }
  onReady() {
    this.scroll = new ScrollManager();
    this.accordionmediainfo = new AccordionMediaInfo();
    this.map = new Map();
    this.flickity = new FktyManager();
    this.spnav = new SPNav();
    this.showspnav = new ShowSPNav();
    this.mqh = MediaQueryHelper.getInstance();
    this.mqh.init(779);
  }
  onScroll() {
    this.currentnav = new CurrentNav();
    this.lgnavfix = new LgNavfix();
    this.showspnav = new ShowSPNav();
  }
}
