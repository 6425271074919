import {qs} from "imagelogic-tools/src/dom/qs";
import {qsa} from "imagelogic-tools/src/dom/qsa";

export class FktyManager {
  private wrapper: HTMLElement[] = qsa(".carousel-wrapper");
  private readonly carousels: HTMLElement[] = [];
  private fkty: any[] = [];
  private opts: {} = {
    prevNextButtons: false,
    wrapAround: true,
    cellAlign: "left",
    pageDots: false,
  };

  constructor() {
    for (let i = 0; i < this.wrapper.length; i++) {
      this.carousels = qsa("ul", this.wrapper[i]);
      this.instanceFkty(i);
      this.setNextBtnEvent(i);
      this.setPrevBtnEvent(i);
    }
  }

  instanceFkty(i: number) {
    this.fkty[i] = new Flickity(this.carousels[i], this.opts);
  }

  setNextBtnEvent(i: number) {
    const btn: HTMLElement = qs(".next-btn");
    btn.addEventListener("click", () => {
      this.fkty[i].next(true);
    });
  }

  setPrevBtnEvent(i: number) {
    const btn: HTMLElement = qs(".prev-btn");
    btn.addEventListener("click", () => {
      this.fkty[i].previous(true);
    });
  }
}
