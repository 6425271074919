import {EVENT_CLICK} from "../Consts";
import {qs} from "imagelogic-tools/src/dom/qs";
import {qsa} from "imagelogic-tools/src/dom/qsa";
import {MediaQueryHelper} from "imagelogic-tools/src/viewport/MediaQueryHelper";
import {SmoothScroll} from "imagelogic-tools/src/motion/SmoothScroll";
import {SmoothScrollOptions} from "imagelogic-tools/src/motion/SmoothScroll";
import {EasingType} from "imagelogic-tools/src/motion/Easing";

export class ScrollManager {
  constructor() {
    qsa("*[data-ss-target]").forEach((elm) => {
      elm.addEventListener(EVENT_CLICK, () => {
        let targetStr = elm.dataset.ssTarget;
        let target = qs(`*[data-ss-id="${targetStr}"]`);
        this.scrollTo(target);
      });
    });
    this.smClickClose();
  }
  smClickClose() {
    qsa("#smNav ul li").forEach( (e) => {
      e.addEventListener(EVENT_CLICK, () => {
        qs("#smNav").classList.remove("open");
        qs("#smNavBtn").classList.remove("open");
      });
    });
  }

  public scrollTo(target: HTMLElement) {
    let mqi = MediaQueryHelper.getInstance().currentMQIndex;
    let offset = mqi === 0 ? 60 : 0;
    let sso: SmoothScrollOptions = {
      target: target,
      duration: 900,
      easing: EasingType.easeOutQuart,
      offset: offset
    };
    SmoothScroll.animate(sso);
  }
}
